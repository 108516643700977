.infoText {
  font-size: 14px;
  color: #868e96;
  margin-top: 20px;
}

.qnaDiv {
  cursor: pointer;
  margin-top: 20px;
}
.qnaDiv:hover {
  background-color: #ebebeb;
}
.titleTexxt {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  white-space: nowrap;
  white-space: normal;
  line-height: 1.5;
  max-height: "3px";
  text-align: left;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  font-weight: 700;
  color: #1b1c1d;
}

.contentText {
  margin-bottom: 12px;
  max-height: 44px;
  color: #616568;
  overflow: hidden;
  font-size: 15px;
}

.bottomText {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  color: #858a8d;
}

.answerWrap {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.answerDiv {
  flex-direction: row;
  width: 80px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #dee2e6;
}
