/* .wrapper {
  background-image: url("../img/main-bg.jpg");
  width: 2000px;
  background-position: center left 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
} */

.title {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 17px;
  letter-spacing: 2px;
  z-index: 99;
  top: 0;
  padding: 30px 7px 10px;
  width: 100%;
  text-align: right;
  background-color: rgba(0, 0, 0, 0.3);
  font-family: monospace;
}

.float {
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  padding-top: 28px;
  color: #fff;
}
.arrow_box {
}
/* .arrow_box:after {
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213, 198, 100, 0);
  border-top-color: antiquewhite;
  border-width: 21px;
  bottom: -34px;
  right: 25px;
} */

.arrow_box2 {
}
/* .arrow_box2:after {
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213, 198, 100, 0);
  border-top-color: lightgoldenrodyellow;
  border-width: 21px;
  bottom: -34px;
  right: 25px;
} */

/* @font-face {
  font-family: "Sam3KRFont";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_eight@1.0/Sam3KRFont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Vitro_core";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10-21@1.0/Vitro_core.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
.font {
  font-family: "Vitro_core";
}

.center {
  position: absolute;
  /* top: 50%;
  transform: translate(-50%, -50%); */
  /* z-index: 999999999999999; */
  width: 10px;
  height: 10px;
  /* background-color: red; */
}
