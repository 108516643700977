.menu_topbox {
  border-radius: 15px;
  background-color: #a82126;
  padding: 20px;
  color: white;
  text-align: center;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}
.menu_topbox_box {
  text-align: center;
  width: 50%;
}
.border_right {
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.menu_topbox_box span {
  font-size: 35px;
  font-weight: bold;
}
.menubox {
  border-radius: 5px;
  color: #a82126;
  text-align: center;
  padding: 13px;
  font-size: 35px;
  cursor: pointer;
}
.menubox_text {
  font-size: 15px;
  font-weight: bold;
  color: #333;
}
