.sort {
  text-align: right;
  margin-right: 7px;
  font-size: 24px;
}

.sort svg {
  cursor: pointer;
}
.title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}
.list {
  border-radius: 7px;
  box-shadow: 0 0 10px gray;
  min-height: 205px;
  margin-bottom: 20px;
  cursor: pointer;
}

.list2 {
  border-radius: 7px;
  box-shadow: 0 0 10px gray;
  min-height: 205px;
  margin-bottom: 20px;
  cursor: pointer;
}

.pd {
  padding: 10px;
}

.bg {
  width: 100%;
  height: 130px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 101%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.bg2 {
  width: 150px;
  height: 150px;
  background-color: red;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.infoWrap {
}

.calWrap {
  position: absolute;
  width: 80%;
  max-width: 500px;
}

.topCategory {
  text-align: center;
  padding-top: 70px;
}
.topCategory span {
  display: inline-block;
  width: 33.3%;
  padding: 15px 0;
  cursor: pointer;
}

.topCategory span.active {
  color: #a82126;
  font-weight: bold;
  border-bottom: 2px solid #a82126;
}
.sub {
  padding: 10px;
}
.sub span {
  margin-right: 10px;
  padding: 4px 12px;
  border: 1px solid #ddd;
  border-radius: 18px;
  cursor: pointer;
}

.sub span.on {
  color: #a82126;
  border: 1px solid #a82126;
}

.keyword {
  border-radius: 0;
}

.imageSize {
  font-size: 20px;
}

.timeStyle {
  font-size: 15px;
}

.timeStyle2 {
  font-size: 15px;
}

.table {
  width: 90%;
  border: 1px solid #ddd;
  margin: 0 auto 20px;
  text-align: center;
}
.table th {
  width: 85px;
}
.table th,
td {
  border: 1px solid #ddd;
  padding: 0.45rem;
  font-size: 14px;
}
