.ab {
  padding: 0 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  bottom: 70px;
}

@media screen and (min-width: 768px) {
  .ab {
    bottom: 57px;
  }
}
