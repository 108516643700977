.wrap {
  background-color: white;
  color: white;
  width: 100%;
  padding: 10px;
  border-radius: 15px 15px 0 0;
  position: fixed;
  left: 0;
  bottom: -100%;
  transition: 0.3s;
  z-index: 98;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
}
.active {
  bottom: 53px;
}

.bookimg {
  width: 100px;
  height: 130px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
}

.booktext h6 {
  color: white;
  font-weight: bold;
}
.booktext p {
  font-size: 12px;
  margin-bottom: 2px;
  color: white;
}

.info {
  font-size: 12px;
  color: white;
}

.info .btn {
  font-size: 12px;
}

.bgactive {
  background-color: rgb(207, 231, 247);
}

.tab {
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
  color: white;
}
.tab.active {
  color: #a82126 !important;
  border-bottom: 2px solid #a82126;
}
