.wrap {
  width: 100vw;
  height: 100vh;
  background: url("../img//login/login_bg.png") no-repeat center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.idwrap {
  display: flex;
  align-items: center;
}
.duplibtn {
  font-size: 12px;
  width: 42%;
}
.white_box {
  width: 80%;
  max-width: 350px;
  background-color: white;
  text-align: center;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
.fs_14 {
  font-size: 14px;
}
