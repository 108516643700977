.profile {
  position: relative;
  width: 150px;
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profile:hover::after {
  content: "변경";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  text-align: center;
  color: #fff;
  vertical-align: middle;
  line-height: 150px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
