.timer {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.timer div {
  font-size: 22px;
  font-family: "LAB디지털";
  width: 120px;
  margin-left: 7px;
}

.timer div.active {
  color: red;
}

.divWrap {
  display: inline-block;
  margin-right: 10px;
}
