.title {
  border-bottom: 1px dotted #ddd;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.subtitle {
  border-bottom: 1px dotted #ddd;
  padding-top: 5px;
  padding-bottom: 5px;
}

.box.left {
  width: 40%;
}

.box.right {
  width: 60%;
  padding: 5px 10px;
}

.box .btn {
  border: 1px solid #ddd;
  color: #fff;
  background-color: purple;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  padding: 5px 0;
}
.box .lineHeight {
  font-size: 14px;
  line-height: 30px;
}
.box2 {
  width: 33.33%;
  text-align: center;
  color:#fff
}

.box2 p {
  font-weight: bold;
  font-size: 36px;
}

.box2 span {
  font-size: 14px;
  color: black;
}

.bottom {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.top {
  padding-top: 10px;
}
