.icon {
  float: left;
  font-size: 36px;
  color: #a82126;
}

.aIcon {
  display: inline;
  font-size: 36px;
  color: #616568;
}

.aIconA {
  float: left;
  font-size: 36px;
}

.aWrap {
  background: #f0efef;
  padding: 40px 0;
}

.titleTexxt {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  white-space: nowrap;
  white-space: normal;
  line-height: 1.5;
  max-height: "3px";
  text-align: left;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  font-weight: 700;
  color: #1b1c1d;
}

.contentText {
  margin-bottom: 12px;
  color: #616568;
  font-size: 15px;
}

.nameWrap {
  font-size: 15px;
  margin-bottom: 15px;
}

.date {
  color: #adb5bd;
}

.acWrap {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 36px;
}
