.cateInfo {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cateInfo span {
  font-size: 12px;
  font-weight: bold;
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #666;
}
.cateInfo2 span:last-child {
  border: none;
}

.wrap {
  font-size: 14px;
}

.keywordWrap {
  position: fixed;
  width: 100%;
  max-width: 768px;
  left:50%;
  transform: translateX(-50%);
  top: 60px;
  z-index: 11;
}
.keyword {
  border-radius: 0;
}
.comment {
  border: 1px solid #a82126;
  font-size: 12px;
  margin-bottom: 13px;
}

.sltWrap {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 122px;
  font-size: 14px;
  margin-bottom: 10px;
}

.sltWrap span {
  display: inline-block;
  padding: 5px;
  width: 40px;
  text-align: center;
  cursor: pointer;
}
.sltWrap span.on {
  background-color: lightblue;
  border-radius: 5px;
}

.test {
  position: absolute;
  right: 0;
  width: 100px;
  padding: 4px 0;
  border: 1px solid aqua;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
}

.test:hover {
  background-color: aqua;
  color: #fff;
}

.mSpan {
  color: lightgray;
  border: 1px solid lightgray;
  display: inline-block;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 15px;
  margin-right: 15px;
  width: 45%;
  cursor: pointer;
}

.mSpan.on {
  color: purple;
  border: 1px solid purple;
}

.mSpan2 {
  color: purple;
  border: 1px solid purple;
  display: inline-block;
  text-align: center;
  padding: 10px 0;
  width: 50%;
  cursor: pointer;
}

.mSpan:nth-child(2n) {
  margin-right: 0;
}

.oxOn {
  color: #a82126 !important;
}

.topWrap {
  position: fixed;
  top: 100px;
  z-index: 1111;
  padding: 10px;
  /* background-color: #f4f8f9; */
  background: aliceblue;
  box-shadow: 1px 2px 3px rgb(0 0 0 / 30%);
  max-width: 768px;
}

.topSpan {
  color: dimgray;
  text-decoration: underline;
  text-underline-position: under;
  font-size: 13px;
  padding: 0 5px;
  position: relative;
  cursor: pointer;
}

.spanOn {
  color: #a82126;
}

.topSpan:first-child {
  padding-left: 0;
}
/* .topSpan::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -1px;
  transform: translateY(-50%);
  width: 2px;
  height: 10px;
  background-color: #21252b;
} */

.appendTitle {
  color: #a82126;
}

.print {
  position: fixed;
  bottom: 70px;
  right: 10px;
  z-index: 1111;
  background-color: #fff;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 50%);
}
