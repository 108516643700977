.title {
  padding-bottom: 12px;
  border-bottom: 1px solid #a82126;
  font-size: 16px;
}
.borderNone {
  border: none;
}
.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.categoryWrap {
  display: flex;
  flex-wrap: wrap;
  /* margin: 0 10px; */
}

/* .categoryWrap {
  padding: 7px 0;
} */
.category {
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
  border: 1px solid lightgray;
  border-radius: 12px;
  padding: 1px 10px;
  padding-bottom: 2px;
  margin-top: 5px;
}

.category:hover {
  background-color: rgb(250, 250, 250);
}
.category.active {
  color: #a82126;
  border: 1px solid #a82126;
}

.thumb {
  width: 100%;
  height: 130px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;
}
.contentWrap {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 25px 0;
}
.contentWrap.margin {
  margin-top: 80px;
}

.contentDiv {
  display: inline-block;
  text-align: center;
  padding: 0 5px;
  margin-bottom: 10px;
  width: 50%;
  vertical-align: top;
}
.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
}
.inputWrap {
  width: 110px;
}
.input {
  border: 0;
  background: 0;
  border-bottom: 1px solid #a82126;
  width: 100%;
  cursor: pointer;
}

.input:focus {
  outline: none;
}

.cal {
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
}

.name {
  font-size: 13px;
  font-weight: bold;
  padding: 0 10px;
}

.date {
  font-size: 12px;
  padding-bottom: 5px;
  color: darkgrey;
}

.timer {
  font-size: 14px;
}

.keyword {
  /* position: fixed;
  top: 200px; */
  width: 95%;
  /* left: 50%;
  transform: translateX(-50%); */
  border: none;
  border-bottom: 1px solid #a82126;
}
.keyword div {
  position: relative;
}

.keyword:focus {
  outline: none;
}
/* .ab {
  position: absolute;
  right: 10px;
  top: 35px;
} */
