@media screen and (min-width: 768px) {
  .resize {
    max-width: 768px !important;
  }
}

.refit {
  overflow: auto !important;
  height: 100vh;
}
