.itemWrap {
  overflow-y: hidden;
  overflow-x: auto;
  min-height: 260px;
  font-size: 14px;
}
.checked {
  color: #a82126;
  position: inherit;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.checked svg {
  font-size: 36px;
}
.btnWrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.btnWrap button {
  margin-right: 10px;
}

.type {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 2px 10px;
  font-size: 13px;
  box-shadow: 2px 2px 2px #ddd;
  background-color: aliceblue;
}

.type svg {
  font-size: 16px;
  margin-top: -4px;
}

.emptyWrap {
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}

.emptyWrap:hover {
  color: #a82126;
  cursor: pointer;
}

.h6 {
  /* border-bottom: 1px solid #a82126; */
  /* text-shadow: 2px 2px 2px grey; */
  font-size: 18px;
  margin-bottom: 15px;
  color: #fff;
}

.ab {
  position: absolute;
  left: 135px;
  top: 34px;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.main {
  height: 200px;
  width: 100%;
}

.content {
  width: 100%;
  height: 100%;
}

.marker {
  color: white;
  position: absolute;
  top: 0px;
  left: 140px;
  font-family: monospace;
}

.image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
