.textWrap {
  color: lightgray;
  display: inline-block;
}
.textWrap2 {
  color: lightgray;
  display: inline-block;
}

.textActive {
  color: #a82126;
}
.text {
  cursor: pointer;
}
.margin {
  margin-top: -2.5px;
}
span.text {
  display: inline-block;
  vertical-align: middle;
}

.textWrap :hover {
  color: #a82126;
}

.commentWrap {
  padding: 0 15px;
}
.commentTop {
  padding: 10px 0;
}

.customSelect {
  border: none;
  border-bottom: 1px solid black !important;
}

.commentBoxWrap {
  padding: 20px 0 70px;
  overflow: auto;
}
.commentBox {
  margin-bottom: 15px;
  position: relative;
}

.name {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 3px;
}

.comment {
}

.reply {
  cursor: pointer;
  color: #616161;
  display: inline-block;
  position: absolute;
  right: 10px;
}

.reply2 {
  cursor: pointer;
  color: #616161;
  display: inline-block;
}

.replyBox {
  position: absolute;
  background-color: #f3f3f3;
  padding: 15px;
  bottom: 0;
  left: 0;
  width: 100%;
}

.replyArea {
  width: 100%;
  height: 2em;
  border: none;
  resize: none;
}

.send {
  position: absolute;
  right: 25px;
  top: 22px;
  cursor: pointer;
  color: #06f;
  font-size: 20px;
}

.child {
  margin-left: 20px;
  margin-top: 10px;
}

.arrow {
  margin-top: -4px;
}

.rotate {
  position: absolute;
  left: -20px;
  top: 5px;
  transform: rotate(-90deg);
  color: #616161;
}

.nameWrap {
  position: absolute;
  left: 20px;
  top: 18px;
  color: #06f;
}
.small {
  font-size: 12px !important;
}

.removeBtn {
  position: absolute;
  right: 14px;
  top: 5px;
  cursor: pointer;
}
