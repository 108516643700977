.toptitleWrap {
  width: 100%;
  height: 60px;
  /* background-color: #ae73d2; */
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 6px rgb(0 0 0 / 50%);
}
.toptitle {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  max-width: 768px;
}
.topBottom {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  background-color: white;
  z-index: 111;
}

.topBottom .cate {
  padding: 0 10px;
}
.topBottom .cate:active {
  background-color: #f4f8f9;
  border-radius: 50%;
}
.topBottom .cate.on {
  color: #a82126;
  text-shadow: 2px 2px 2px gray;
  font-weight: bold;
}

.topBottom .op {
  opacity: 0;
  transform: translateX(50px);
}

.topBottom .opOn {
  opacity: 1;
  transition: 0.3s;
}

.bottombtn {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #333;
  text-align: center;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottombtn button {
  max-width: 300px;
}
.main {
  margin: 100px 0;
}

.omrdiv {
  position: fixed;
  width: 85%;
  max-width: 500px;
  max-height: 500px;
  overflow: scroll;
  top: 70px;
  right: 0;
  background-color: white;
  z-index: 9999;
  border: 1px solid #333;
}

.omrdiv table td:nth-child(1) {
  width: 50px;
  background-color: #eaeaea;
}
.omrdiv table td {
  border: 1px solid #eaeaea;
}
.omrdiv table td input[name="test"]:checked + label {
  color: red;
}

.centerText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* color: black; */
  z-index: 111;
}

.personWrap {
  margin-left: auto;
}
.searchIcon {
  font-size: 26px;
  cursor: pointer;
}

.bgActive {
  margin: 0 7px;
  border-radius: 50%;
}
/* .bgActive:active {
  background-color: #f4f8f9;
} */
.person {
  font-size: 26px;
  cursor: pointer;
}

.bell_number {
  position: absolute;
  font-size: 10px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  background-color: red;
  top: -16px;
  right: -36px;
}
.reddot {
  font-size: 10px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  background-color: red;
}
.alarmList {
  padding: 5px 20px;
  font-size: 13px;
}
.alarmList div {
  font-size: 10px;
  text-align: right;
  color: rgba(0, 0, 0, 0.4);
}
