@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
@import url("//cdn.jsdelivr.net/npm/font-kopub@1.0/kopubdotum.min.css");
@font-face {
  font-family: "ChosunSm";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@1.1/ChosunSm.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "LAB디지털";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/LAB디지털.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Arial Unicode MS";
  src: url("./fonts/ARIALUNI.TTF") format("ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
}

a {
  color: black;
  text-decoration: none !important;
}

.App {
  text-align: center;
}

.react-confirm-alert-button-group {
  justify-content: space-around !important;
}
.react-confirm-alert-button-group > button {
  font-size: 14px !important;
  margin-right: 0 !important;
  padding: 6px 50px !important;
}

.react-confirm-alert-button-group > button:first-child {
  background-color: #0d6efd;
}

.toast-div {
  word-break: keep-all;
}

.pointer {
  cursor: pointer;
}

.main-color {
  background-color: #a82126 !important;
  border-color: #a82126 !important;
}

.ql-container {
  min-height: 300px;
}

.rw-cell.disabled {
  color: #ddd;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0 !important;
}
ul {
  list-style: none;
  padding: 0;
}
ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 3px;
}
/* ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
}
ul.pagination li:last-child {
  border-radius: 0 5px 5px 0;
} */
ul.pagination li a {
  text-decoration: none;
  color: #a82126;
  font-size: 1rem;
  margin-bottom: 2px;
}
ul.pagination li.active a {
  color: white !important;
}
ul.pagination li.active {
  background-color: #a82126;
}
ul.pagination li a:hover,
ul.pagination li a.active {
  opacity: 0.4;
}
.page-selection {
  width: 48px;
  height: 30px;
  color: #a82126;
}

.rw-calendar-contained {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

::-webkit-scrollbar {
  /* display: none; */
}

.Toastify {
  font-size: 13px;
}

.text-purple {
  color: #6171ff !important;
}

#zmmtg-root {
  display: none;
}

.css-lx4kgr {
  margin-top: 60px;
  width: 100% !important;
}
.css-19kzrtu {
  padding: 12px !important;
  padding-top: 0px !important;
}
.css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #a82126 !important;
  font-weight: bold;
}

.css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
  color: #a82126 !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #a82126 !important;
}
.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
  font-family: "Noto Sans KR", sans-serif !important;
}

.css-10hburv-MuiTypography-root {
  color: #000;
  font-weight: bold !important;
}
.css-i4bv87-MuiSvgIcon-root {
}

.noborder_table td {
}

.css-251er.Mui-selected {
  color: #a82126 !important;
  font-weight: bold !important;
}

a {
  color: #000 !important;
}

.css-ttwr4n {
  background-color: #a82126 !important;
}

#root {
  /* background-color: #6455e0; */
  margin: 0 auto;
  background-image: url(./img/main-bg2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.position-fixed {
  position: fixed;
  top: 0;
  z-index: 8888;
}

.MuiTableHead-root .MuiTableCell-root {
  padding: 6px 10px;
  text-align: center;
}
.MuiTableCell-root {
  border: 0;
  text-align: center !important;
}

.react-confirm-alert-body > h1 {
  font-size: 20px !important;
}

pre {
  white-space: -moz-pre-wrap;
  /* Mozilla, supported since 1999 */
  white-space: -pre-wrap;
  /* Opera */
  white-space: -o-pre-wrap;
  /* Opera */
  white-space: pre-wrap;
  /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word;
  /* IE 5.5+ */
  font-size: 16px !important;
}
.slick-slide {
  padding: 0 5% 0 0;
}

/* .slick-track {
  max-width: 100vw;
  width: 100%;
} */

h5 {
  color: white;
}
.yes-print {
  display: none;
}

.jimoonWrap {
  font-size: 14px !important;
  font-weight: 300 !important;
  /* font-family: ChosunSm !important; */
  font-family: "KoPub Dotum" !important;
}

.jimoonWrap span,
.jimoonWrap p {
  font-size: 14px !important;
  font-weight: 300 !important;
  /* font-family: ChosunSm !important; */
  font-family: "KoPub Dotum" !important;
}

.tdu {
  text-decoration: underline;
}

@media (hover: hover) {
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
}

@page {
  size: auto; /* auto is the initial value */

  /* this affects the margin in the printer settings */
  margin: 25mm 25mm 25mm 25mm;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .yes-print {
    display: block !important;
  }
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.graph_chart text {
  fill: white;
}


.Toastify__toast-container{
  width: 90% !important;
  max-width: 800px !important;
}