.wrap {
  width: 100%;
  height: calc(100vh + 150px);
  background-color: #f4f8f9 !important;
  z-index: 11111;
}

.top {
  height: 50px;
}

.top svg {
  font-size: 32px;
}

.topInput {
  border: 0;
}

.topInput:focus {
  outline: none;
}

.ab {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ab.left {
  left: 13px;
}

.info .ab.right {
  right: 10px;
  margin-top: -7px;
  cursor: pointer;
}

.thum {
  width: 120px;
  height: 150px;
  display: inline-block;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.info {
  vertical-align: top;
  display: inline-block;
  padding: 0 10px;
}
.info ul {
  padding: 0;
  line-height: 150px;
  margin: 0;
}

.grid {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 16px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.itemWrap {
  overflow: scroll;
  overflow-x: hidden;
  height: calc(100vh - 150px);
}
