.toptitle {
  position: fixed;
  width: 100%;
  height: 70px;
  top: 0;
  left: 0;
  background-color: #333;
  color: white;
  display: flex;
  align-items: center;
  z-index: 99;
}
.bottombtn {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #333;
  text-align: center;
  z-index: 1111;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottombtn button {
  max-width: 300px;
}
.nameWrap {
  margin: 60px 0 7px;
  text-align: right;
}
.main {
  margin-bottom: 100px;
  border: 1px solid black;
  border: 1px solid lightslategrey;
  padding: 20px;
  height: 100vh;
  height: auto;
}

.main img {
  max-width: 100%;
}

.omrdiv {
  margin: 0 auto;
  background-color: white;
  border: 1px solid #333;
  position: fixed;
  bottom: 70px;
  left: 0;
  width: 100%;
  height: 262px;
  overflow: scroll;
  z-index: 999999;
}
.close {
  position: fixed;
  right: 5px;
  /* top: -30px; */
  bottom: 340px;
  font-size: 28px;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 5px;
  background: #ddd;
  color: purple;
  cursor: pointer;
}

.omrdiv table td:nth-child(1) {
  width: 50px;
  background-color: #eaeaea;
}
.omrdiv table td {
  border: 1px solid #eaeaea;
  cursor: pointer;
}
.omrdiv table td input[name="test"]:checked + label {
  color: red;
}

.grading {
  position: absolute;
  left: -30px;
  top: -25px;
  width: 100px;
}

.subTop {
  position: fixed;
  top: 70px;
  background-color: #e9ecef;
  left: 0;
  right: 0;
  height: 45px;
  z-index: 1;
}

.active {
  color: aliceblue;
  background-color: lightblue !important;
}

.preTitle {
  margin-bottom: -10px;
  background-color: #fff;
  position: relative;
  display: inline-block;
  bottom: -12px;
  left: 10px;
  /* font-family: "ChosunSm"; */
  font-family: "KoPub Dotum";
}
.pre {
  border: 1px solid #ddd;
  padding: 10px;
  padding-top: 15px;
  /* font-family: "ChosunSm"; */
  font-family: "KoPub Dotum";
}

.font {
  /* font-family: "ChosunSm" !important; */
  font-family: "KoPub Dotum" !important;
}

.testTop {
  /* font-family: "ChosunSm" !important; */
  font-family: "KoPub Dotum" !important;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
  font-weight: bold;
}

.testTop span {
  /* font-family: "ChosunSm" !important; */
  font-family: "KoPub Dotum" !important;
  float: right;
}
.testName {
  /* font-family: "ChosunSm" !important; */
  font-family: "KoPub Dotum" !important;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: inset;
  padding: 7px 0;
}

.upDown {
  position: fixed;
  bottom: 100px;
  right: 20px;
  font-size: 40px;
  cursor: pointer;
  z-index: 100;
}

.omrbtn {
  position: fixed;
  bottom: 155px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
}

.print {
  position: fixed;
  bottom: 210px;
  right: 18px;

  cursor: pointer;
  z-index: 100;
}

.print svg {
  font-size: 44px;
}
.printSpan {
  font-size: 12px;
  text-shadow: 1px 1px 1px darkgrey;
}
.printSpan:hover {
  color: #a82126;
}
.upDown.bottom {
  bottom: 150px;
}
.upDown:hover {
  color: #a82126;
}

.margin {
  margin-top: -4px;
}

.elecFont {
  font-family: "LAB디지털";
}

.under {
  text-decoration: underline;
  text-underline-position: under;
}

.topOn {
  color: #06f;
}

.topSpan {
  border: 1px solid #ddd;
  width: 100px;
  font-size: 14px;
  vertical-align: top;
  word-break: keep-all;
  white-space: nowrap;
  text-align: center;
}

.Grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

@media screen and (max-width: 718px) {
  .Grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.CardContainer {
  width: 100%;
  position: relative;
}

.CardContainer::after {
  display: block;
  content: "";
  padding-bottom: 100%;
}

.CardBox {
  border: 2px solid #666;
  background-color: white;
  border-radius: 10px;
  font-weight: bold;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jimoon {
  font-size: 13px;
  border: 1px solid black;
}

.cardborder {
  border: 6px solid black !important;
}

.printDiv {
  display: none;
}

@media print {
  .printDiv {
    display: block;
  }
}

.down {
  top: 50px;
}

.moTable td {
  font-size: 12px;
}
