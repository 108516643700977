/* .formCheck {
  float: left;
} */

.formWrap {
  padding-left: 1.5rem;
  margin-bottom: 0.7rem;
  display: flex;
}
.form {
  display: inline;
  padding: 0;
  margin-right: 0.25rem;
}

.inline {
  display: inline;
  cursor: pointer;
}
