.titleName {
  position: absolute;
  top: 4px;
  left: 5px;
}
.title {
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  width: 100%;
  padding-left: 44px;
}
