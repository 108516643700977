.input {
  border: none;
  border-color: #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  width: 65px;
  text-align: right;
  margin-right: 5px;
}

.item {
  background-color: #fff;
  height: calc(100vh - 250px);
  width: 90%;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  padding: 5px 10px;
  margin: 5px 0;
  position: relative;
  max-width: 600px;
  transition: 0.3s;
}
.on {
  transform: scaleX(-1);
}

.on .content,
.on .content2 {
  transform: scaleX(-1) translate(50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  cursor: pointer;
}

.grade {
  text-align: right;
}

.on .grade {
  text-align: left;
}
.content,
.content2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.content2 {
  width: 100%;
  padding: 10px;
}
.content .text {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0;
}

.content2 .text {
  margin-bottom: 0;
  font-size: 14px;
}
