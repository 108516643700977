.wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  background-color: #fff;
}
.center {
  left: 50%;
  transform: translate(-50%, 0);
}
.item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 20%;
  font-size: 12px;
  cursor: pointer;
}

.item svg {
  font-size: 28px;
  color: #a82126;
}

.item div {
  margin-top: 5px;
}
