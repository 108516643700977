.sidemenu {
  position: fixed;
  top: 60px;
  left: 0;
  width: 65%;
  max-width: 300px;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  z-index: 9999;
}
.sidemenu_menu {
  width: 100%;
  cursor: pointer;
  padding: 20px 15px;
  color: black;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.bodycover {
  position: fixed;
  top: 60px;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
}
